<template>
  <v-container class="ma-0 pa-0 chief-container">
    <v-row>
      <v-col lg="9" md="12">
        <quillEditor class="quill-editor" v-model="bodyData.chiefComplaints" />
        <v-bottom-navigation
          grow
          height="50"
          absolute
          class="elevation-0 exam-bottom-controller"
          hide-on-scroll
        >
          <v-btn
            ripple
            color="#0065FF"
            class="px-4 py-3 white--text pl-10 pr-10"
            @click="UpdateChiefComplaints()"
            v-bind:disabled="isFinishExamination === true ? true : false"
          >
            {{ $t("common.SaveData") }}
          </v-btn>
        </v-bottom-navigation>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import i18n from "@/plugins/i18n";

import { quillEditor } from "vue-quill-editor";
import WorkListService from "@/services/work-list";
import BookingService from "@/services/booking";
import EpisodeService from "@/services/episode";

export default {
  name: "ChiefComplaintsPage",
  components: {
    quillEditor,
  },
  data() {
    return {
      bodyData: {
        chiefComplaints: "",
      },
      bookingID: "",
      episodeID: null,
      isFinishExamination: false,
    };
  },
  async created() {
    await this.getChiefComplaintByID(this.$route.params.episodeRecordID);
  },
  methods: {
    showError(message) {
      this.$toast.error(message);
    },
    showSuccess(message) {
      this.$toast.success(message);
    },
    async getChiefComplaintByID(episodeRecordID) {
      if (!episodeRecordID) return;
      var result = await WorkListService.GetByEpisodeRecordID(episodeRecordID);
      if (!result || result.error) {
        this.showError(
          "Cannot get ChiefComplaints at the moment! Please try again later."
        );
      }
      console.log(result);
      this.episodeID = result.episodeID;
      this.bookingID = result.admissionInfo.bookingID;
      if (result.admissionInfo.chiefComplaints) {
        this.bodyData.chiefComplaints = result.admissionInfo.chiefComplaints;
      }
      await this.getEpisodeInfo();
    },
    async getEpisodeInfo() {
      const result = await EpisodeService.getEpisodeDetail(this.episodeID);
      if (!result || result.error) {
        this.showError(
          "Can not get episode information. Please try again later."
        );
        return;
      }
      if (result.statusID == 2) {
        this.isFinishExamination = true;
      }
    },
    async UpdateChiefComplaints() {
      var bodyData = this.bodyData;
      var result = await BookingService.UpdateChiefComplaintsByBookingID(
        this.bookingID,
        bodyData
      );
      if (!result || result.error) {
        this.showError(
          "Cannot save ChiefComplaints at the moment! Please try again later."
        );
        return;
      }
      this.showSuccess(i18n.t("message.chiefComplain"));
    },
  },
};
</script>

<style lang="scss">
.chief-container {
  height: 100vh;
  .quill-editor {
    height: 47vh;
  }
  .v-btn {
    float: right;
  }
}
.exam-bottom-controller {
  background-color: #f5f5f5 !important;
  button {
    background-color: #0277bd !important;
    .v-btn__content {
      color: white !important;
      font-size: 120%;
      text-transform: uppercase;
    }
  }
  button:disabled {
    background-color: #bdbdbd !important;
    .v-btn__content {
      color: #424242 !important;
    }
  }
}
.v-item-group.v-bottom-navigation {
  display: flex;
  width: calc(100% - 16px) !important;
  bottom: 15px;
}
</style>
